import React from 'react';
import Image from '../elements/Image';
import { useState } from "react";

const ContactForm = () => {
  const [formData, setFormData] = useState({});

  const sendEmail = (e) => {
    e.preventDefault();

    if (!formData.hasOwnProperty('name') || formData.name === '') {
      alert("please fill all information");
      return;
    }
    if (!formData.hasOwnProperty('company') || formData.name === '') {
      alert("please fill all information");
      return;
    }
    if (!formData.hasOwnProperty('email') || formData.name === '') {
      alert("please fill all information");
      return;
    }
    if (!formData.hasOwnProperty('phone') || formData.name === '') {
      alert("please fill all information");
      return;
    }
    if (!formData.hasOwnProperty('message') || formData.name === '') {
      alert("please fill all information");
      return;
    }
    let body = `
      company: ${formData?.company}
      ${formData?.message}
    `;
    console.log("xx:", body);
    window.location.href = `mailto:ahnaf@halicon.io?subject='Hello from ${formData?.name}'&body=${body}`;
  };

  const onFormChange = (field, v) => {
    setFormData({
      ...formData,
      [field]: v
    });
  }

  return (
    <section className="contact-form">
      <div className="container">
        <div className="row">
          <div className="col-md-4 reveal-from-bottom" data-reveal-delay="600">
            <div>
              <h2 className="mt-3 mb-28">We are around</h2>
              <div className="mt-16 d-flex">
                <Image
                  src={require('./../../assets/images/location-ico.png')}
                  alt="creativity-meet" 
                  style={{width: '15px', height: '20px'}}
                />
                <span className="text-xxs ml-16">902 Grand Avenue Saint Paul Minnesota 55015</span>
              </div>
              <div className="mt-16 d-flex align-items-center">
              <Image
                src={require('./../../assets/images/mail-ico.png')}
                alt="creativity-meet" 
                style={{width: '15px', height: '12px'}}
              />
              <span className="text-xxs ml-16">Ahnaf@halicon.io</span>
            </div>
            </div>
          </div>
          <div className="col-md-8 reveal-from-bottom form-col" data-reveal-delay="600">
            <form>
              <div className="row">
                <div className="col-md-6 form-group">
                  <label className="text-xxs text-color-primary" htmlFor="name">Name</label>
                  <input
                    type="name"
                    name="name"
                    className="form-control"
                    id="name"
                    onKeyUp={(e) => {onFormChange('name', e.target.value)}}
                    required
                  />
                </div>
                <div className="col-md-6 form-group">
                  <label className="text-xxs text-color-primary" htmlFor="email">Email Address</label>
                  <input
                    type="email"
                    name="email"
                    className="form-control"
                    id="email"
                    onKeyUp={(e) => {onFormChange('email', e.target.value)}}
                    required
                  />
                </div>
                <div className="col-md-6 form-group">
                  <label className="text-xxs text-color-primary" htmlFor="company">Company</label>
                  <input
                    type="text"
                    name="company"
                    className="form-control"
                    id="company"
                    onKeyUp={(e) => {onFormChange('company', e.target.value)}}
                    required
                  />
                </div>
                <div className="col-md-6 form-group">
                  <label className="text-xxs text-color-primary" htmlFor="phone">Phone</label>
                  <input
                    type="text"
                    name="phone"
                    className="form-control"
                    id="phone"
                    onKeyUp={(e) => {onFormChange('phone', e.target.value)}}
                    required
                  />
                </div>
                <div className="col-md-12 form-group">
                  <label className="text-xxs text-color-primary" htmlFor="message">How can we help?</label>
                  <textarea
                    name="message"
                    className="form-control"
                    id="message"
                    style={{height : '200px'}}
                    onKeyUp={(e) => {onFormChange('message', e.target.value)}}
                    required
                  />
                </div>
                <div className="col-md-12 form-group">
                  <button type="button" className="button button-primary button-wide-mobile button-sm mt-2" onClick={sendEmail}>
                    Submit
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
}

export default ContactForm;