import React from 'react';
import classNames from 'classnames';
import { SectionSplitProps } from '../../utils/SectionProps';
import Image from '../elements/Image';

const propTypes = {
  ...SectionSplitProps.types
}

const defaultProps = {
  ...SectionSplitProps.defaults
}

const ServicesBrief = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  invertMobile,
  invertDesktop,
  alignTop,
  imageFill,
  ...props
}) => {

  const outerClasses = classNames(
    'services-brief section',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'services-brief-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  const splitClasses = classNames(
    'split-wrap',
    invertMobile && 'invert-mobile',
    invertDesktop && 'invert-desktop',
    alignTop && 'align-top'
  );

  return (
    <section
      {...props}
      className={outerClasses}
    >
      <div className="container" id="services">
        <div className={innerClasses}>
          <h2 className="mt-0 mb-28 text-center reveal-from-bottom">We specialize in <span className="text-color-primary">Engineering</span></h2>
          <p className="mb-60 text-center reveal-from-bottom is-revealed">The world is constantly changing, HALICON ensures that your business changes with it! Let it be a mobile app, or a complex IoT system that requires an regulated analytics platform, we got you covered.</p>
          <div className={splitClasses}>
            <div className="split-item">
              <div className="split-item-content center-content-mobile reveal-from-left" data-reveal-container=".split-item">
                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                  Web Development
                </div>
                <h3 className="mt-0 mb-12">
                  WEBSITES
                </h3>
                <p className="m-0">
                  Crafting beautiful websites with the ability to take huge traffic, from landing pages to enterprise cloud-based platforms – our teams know the art of getting it done!  
                </p>
              </div>
              <div className={
                classNames(
                  'split-item-image center-content-mobile reveal-from-bottom',
                  imageFill && 'split-item-image-fill'
                )}
                data-reveal-container=".split-item">
                <Image
                  src={require('./../../assets/images/services-3.jpg')}
                  alt="Features split 01"
                  width={528}
                  height={396} />
              </div>
            </div>

            <div className="split-item">
              <div className="split-item-content center-content-mobile reveal-from-right" data-reveal-container=".split-item">
                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                  Mobile Development
                </div>
                <h3 className="mt-0 mb-12">
                  MOBILE APPS
                </h3>
                <p className="m-0">
                  Since the beginning, our engineering team has experts who have seen the mobile platforms evolve. From native to cross-platform frameworks, we know the drill.
                </p>
              </div>
              <div className={
                classNames(
                  'split-item-image center-content-mobile reveal-from-bottom',
                  imageFill && 'split-item-image-fill'
                )}
                data-reveal-container=".split-item">
                <Image
                  src={require('./../../assets/images/services-1.jpg')}
                  alt="Features split 02"
                  width={528}
                  height={396} />
              </div>
            </div>

            <div className="split-item">
              <div className="split-item-content center-content-mobile reveal-from-left" data-reveal-container=".split-item">
                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                  Blockchain Technology
                </div>
                <h3 className="mt-0 mb-12">
                  DECENTRALIZED APPS
                </h3>
                <p className="m-0">
                  Halicon has been recently focusing on the variety application development that can operate autonomously, typically through the use of smart contracts, and run on a decentralized computing, blockchain or other distributed ledger system.
                </p>
              </div>
              <div className={
                classNames(
                  'split-item-image center-content-mobile reveal-from-bottom',
                  imageFill && 'split-item-image-fill'
                )}
                data-reveal-container=".split-item">
                <Image
                  src={require('./../../assets/images/services-4.jpg')}
                  alt="Features split 03"
                  width={528}
                  height={396} />
              </div>
            </div>

            <div className="split-item">
              <div className="split-item-content center-content-mobile reveal-from-left" data-reveal-container=".split-item">
                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                  UI/UX GRAPHIC DESIGN
                </div>
                <h3 className="mt-0 mb-12">
                  CREATIVE DESIGN
                </h3>
                <p className="m-0">
                  From project inception to actually transforming ideas into the real UX, our designers have years of experience and equipped with all the latest happenings in the UI world to truly win the customers’ hearts.
                </p>
              </div>
              <div className={
                classNames(
                  'split-item-image center-content-mobile reveal-from-bottom',
                  imageFill && 'split-item-image-fill'
                )}
                data-reveal-container=".split-item">
                <Image
                  src={require('./../../assets/images/services-2.jpg')}
                  alt="Features split 03"
                  width={528}
                  height={396} />
              </div>
            </div>

          </div>
        </div>
      </div>
    </section>
  );
}

ServicesBrief.propTypes = propTypes;
ServicesBrief.defaultProps = defaultProps;

export default ServicesBrief;