import React from 'react';
import { Link } from 'react-router-dom';
import Image from '../elements/Image';

const AboutBrief = ({layoutPath}) => {

  return (
    <section id="aboutUs" className="aboutus-brief">
      <div className="container">
        <div className="row">
          <div className="col-md-6 reveal-from-bottom" data-reveal-delay="600">
            { 
              layoutPath !== 'main'
              ? <div className="text-xxs text-color-primary fw-600 tt-u mb-8">ABOUT US</div>
              : null
            }
            <h2 className="mt-3 mb-28">WHEN CREATIVITY MEETS <span className="text-color-primary">EXPERIENCE</span></h2>
            <p className="m-0 text-xxs">
              Halicon is a fast-paced digital engineering agency with a primary focus on customer satisfaction. With extensive experience in web and mobile apps, we have gone through an evolution and understand the process of building scalable solutions for your businesses.
            </p>
          </div>
          <div className="col-md-6 d-flex justify-content-center align-items-center reveal-from-bottom" data-reveal-delay="600">
            <Image
              src={require('./../../assets/images/creativity-meets-experience.png')}
              alt="creativity-meet" 
            />
          </div>
        </div>
      </div>
    </section>
  );
}

export default AboutBrief;