import React, { useEffect } from 'react';
// import sections
import ContactForm from '../components/sections/ContactForm';

const Contacts = () => {
  useEffect(() => {
    window.scroll({top: 0, left: 0, behavior: 'smooth'});
  }, []);

  return (
    <>
      <div style={{paddingTop: '160px'}}></div>
      <ContactForm />
    </>
  );
}

export default Contacts;