import React, { useEffect } from 'react';
// import sections
import Hero from '../components/sections/Hero';
import Summary from '../components/sections/Summary';
import ClientsTiles from '../components/sections/ClientsTiles';
import AboutBrief from '../components/sections/AboutBrief';
import ServicesBrief from '../components/sections/ServicesBrief';
import LocationSection from '../components/sections/LocationSection';

const Home = () => {
  useEffect(() => {
    window.scroll({top: 0, left: 0, behavior: 'smooth'});
  }, []);

  return (
    <>
      <Hero className="illustration-section-01" />
      <Summary />
      <AboutBrief />
      <ServicesBrief invertMobile imageFill className="illustration-section-02" />
    </>
  );
}

export default Home;