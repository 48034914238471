import React from 'react';

const Summary = () => {
  return (
    <section className='summary'>
      <div className="container">
        <div className="row">
          <div className="col-md-4 my-2">
            <div className="desc-box reveal-from-bottom" data-reveal-delay="200">
              <div className="text-xxs text-color-primary fw-600 tt-u mb-8">Fortune 500 and Startups alike</div>
              <p className="text-sm fw-600 mt-3 mb-0">We take pride having served Fortune 500 US companies and startups on our customers list.</p>
            </div>
          </div>
          <div className="col-md-4 my-2">
            <div className="desc-box reveal-from-bottom" data-reveal-delay="200">
              <div className="text-xxs text-color-primary fw-600 tt-u mb-8">Dedicated Presence</div>
              <p className="text-sm fw-600 mt-3 mb-0">We intentionally maintain a very limited number of clients at a given time, so we can focus on quality.</p>
            </div>
          </div>
          <div className="col-md-4 my-2">
            <div className="desc-box reveal-from-bottom" data-reveal-delay="200">
              <div className="text-xxs text-color-primary fw-600 tt-u mb-8">Experience</div>
              <p className="text-sm fw-600 mt-3 mb-0">Our teams adapt to the latest cutting-edge technologies.</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Summary;